/* eslint-disable */

import { Box, Button, TextField, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { FeatureFlagContext } from '../../../providers/feature-flag-provider';

interface PropTypes {
    control: any;
    register: any;
    errors: any;
}

const ProgrammingAssignmentCodeFields = (props: PropTypes) => {
    const { control, register, formState, errors } = props;
    const [codeFiles, setCodeFiles] = useState(['main.dart']);
    const [filename, setFilename] = useState('');
    const [filenameError, setFilenameError] = useState<undefined | string>(undefined);

    // TODO: get grader images from assignment-api
    /*
        select distinct grader_image
        from programming_assignments
     */

    const { t } = useTranslation();

    const addCodeFile = () => {
        if (filename.length === 0) {
            setFilenameError('Filename must not be empty');
            return;
        }

        if (!codeFiles.includes(filename)) {
            setCodeFiles([...codeFiles, filename]);
        } else {
            setFilenameError('File with that name already exists!');
        }
    };

    const onFilenameChange = (e) => {
        console.info(e.target.value);
        setFilename(e.target.value);
        setFilenameError(undefined);
    };

    return (
        <>
            {[...codeFiles].map((filename) => (
                <div>
                    <TextField
                        key={filename}
                        label={filename}
                        multiline
                        fullWidth
                        helperText={errors.filename ? errors.filename.message : ''}
                        error={!!errors.filename}
                        {...register(filename, { required: true })}
                    />
                </div>
            ))}

            <TextField label={'Filename'} onChange={onFilenameChange} fullWidth />
            <Button
                sx={{ margin: (theme) => theme.spacing(1, 0) }}
                onClick={addCodeFile}
                type="button"
            >
                <Trans i18nKey="Add file" />
            </Button>
        </>
    );
};

export default ProgrammingAssignmentCodeFields;
