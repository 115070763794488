import { Box } from '@mui/material';
import React from 'react';
import ProgrammingAssignmentCreateForm from '../../../components/forms/assignment/programming-assignment-create-form';
import RestrictedByRole from '../../../components/restricted-by-role';

const ContentCreation = (props: JSX.IntrinsicAttributes) => (
    <Box mx={2}>
        <RestrictedByRole role="ADMIN">
            <ProgrammingAssignmentCreateForm />
        </RestrictedByRole>
    </Box>
);

export default ContentCreation;
