/* eslint-disable */

import { Box, Button, TextField, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { FeatureFlagContext } from '../../../providers/feature-flag-provider';
import ProgrammingAssignmentCodeFields from './programming-assignment-code-fields';

interface PropTypes {}

const ProgrammingAssignmentCreateForm = (props: PropTypes) => {
    const { state: featureFlags } = useContext(FeatureFlagContext);

    const [languageOptions, setLanguageOptions] = useState([{ label: 'test', value: 'test' }]);
    const [topicOptions, setTopicOptions] = useState([]);

    // TODO: get grader images from assignment-api
    /*
        select distinct grader_image
        from programming_assignments
     */

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <>
            <div style={{ maxWidth: '500px' }}>
                <Typography component="h4" variant="h5">
                    <Trans i18nKey="createProgrammingAssignment" />
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="language"
                        control={control}
                        rules={{
                            // required: { value: true, message: 'Grader image is required' },
                        }}
                        render={() => (
                            <Select
                                placeholder={t('programmingLanguage')}
                                options={languageOptions}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Put dropdown before ace editor
                            />
                        )}
                    />
                    {errors?.language?.message}
                    <Controller
                        name="topic"
                        control={control}
                        rules={{ required: { value: true, message: 'Content topic is required' } }}
                        render={() => (
                            <Select
                                placeholder={t('contentTopics')}
                                noOptionsMessage={(input) =>
                                    `No options avaiable for input ${input}`
                                }
                                options={topicOptions}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Put dropdown before ace editor
                            />
                        )}
                    />
                    <TextField
                        label={'Grader image'}
                        fullWidth
                        helperText={errors.graderImage ? errors.graderImage.message : ''}
                        error={!!errors.graderImage}
                        {...register('graderImage', { required: true })}
                    />
                    <TextField
                        multiline
                        label={'Handout'}
                        fullWidth
                        helperText={errors.handout ? errors.handout.message : ''}
                        error={!!errors.handout}
                        {...register('handout', { required: true })}
                    />
                <Typography component="h4" variant="h6">
                    <Trans i18nKey="Starter Code" />
                </Typography>
                <ProgrammingAssignmentCodeFields
                    control={control}
                    errors={errors}
                    register={register}
                />
                <Typography component="h4" variant="h6">
                    <Trans i18nKey="Model solution" />
                </Typography>
                <ProgrammingAssignmentCodeFields
                    control={control}
                    errors={errors}
                    register={register}
                />
                <Typography component="h4" variant="h6">
                    <Trans i18nKey="Tests" />
                </Typography>
                    <ProgrammingAssignmentCodeFields
                        control={control}
                        errors={errors}
                        register={register}
                    />

                    {errors?.topic?.message}
                    <Box display="flex" justifyContent="space-between">
                        <Button sx={{ margin: (theme) => theme.spacing(1, 0) }} onClick={() => {}}>
                            <Trans i18nKey="close" />
                        </Button>
                        <Button sx={{ margin: (theme) => theme.spacing(1, 0) }} type="submit">
                            <Trans i18nKey="create" />
                        </Button>
                    </Box>
                </form>
            </div>
        </>
    );
};

export default ProgrammingAssignmentCreateForm;
